import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { CompositionArticleIds } from "../features/composition/util"
import { RootState } from "./store"

export type ArticleAmounts = {
    [piid: string]: number
}

export type CompositionState = {
    amounts: ArticleAmounts
}

export const createInitialCompositionState = (): CompositionState => ({
    amounts: {}
})

const composition = createSlice({
    name: "composition",
    initialState: createInitialCompositionState(),
    reducers: {
        initializeArticleAmounts: (
            state,
            action: PayloadAction<CompositionArticleIds>
        ) => {
            action.payload.productIds.forEach((id) => {
                state.amounts[id] = 1
            })
            action.payload.accessoryIds.forEach((id) => {
                state.amounts[id] = 0 // accessories should not be selected by default
            })
        },
        incrementArticleAmount: (state, action: PayloadAction<string>) => {
            state.amounts[action.payload] = state.amounts[action.payload] + 1
        },
        decrementArticleAmount: (state, action: PayloadAction<string>) => {
            state.amounts[action.payload] = state.amounts[action.payload] - 1
        },
        setArticleAmount: (
            state,
            action: PayloadAction<{ piid: string; amount: number }>
        ) => {
            const { piid, amount } = action.payload
            state.amounts[piid] = amount
        }
    }
})

export const selectArticleAmount = (piid: string) => (state: RootState) =>
    state.composition.amounts[piid]

export const selectArticleAmounts = () => (state: RootState) =>
    state.composition.amounts

export const {
    initializeArticleAmounts,
    setArticleAmount,
    incrementArticleAmount,
    decrementArticleAmount
} = composition.actions
export default composition.reducer
