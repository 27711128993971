import { Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { selectConfiguratorName } from "../../app/configuratorSlice"
import { useAppSelector } from "../../app/hooks"

export const HeaderTitle = () => {
    const { t } = useTranslation()
    const configuratorName = useAppSelector(selectConfiguratorName)

    return (
        <Typography
            sx={{
                color: "white",
                fontSize: "2em"
            }}
        >
            {configuratorName ?? t("glossary:header.title")}
        </Typography>
    )
}
