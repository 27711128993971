import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ContainerTO } from "@encoway/c-services-js-client"

import { RootState } from "./store"

export type ConfiguratorState = {
    elbridgeId: string | null
    houseId: string | null
    configuratorId: string | null
    configuratorName: string | null
    lang: string | null
    configurationId?: string
    selectedTabId: string | null
    cuiHash: string
}

export const createInitialConfiguratorState = (): ConfiguratorState => ({
    elbridgeId: null,
    houseId: null,
    configuratorId: null,
    configuratorName: null,
    lang: null,
    selectedTabId: null,
    cuiHash: Date.now().toString()
})

const configurator = createSlice({
    name: "configurator",
    initialState: createInitialConfiguratorState(),
    reducers: {
        setElbridgeId: (state, action: PayloadAction<string | null>) => {
            state.elbridgeId = action.payload
        },
        setHouseId: (state, action: PayloadAction<string | null>) => {
            state.houseId = action.payload
        },
        setConfiguratorId: (state, action: PayloadAction<string | null>) => {
            state.configuratorId = action.payload
        },
        setConfiguratorName: (state, action: PayloadAction<string | null>) => {
            state.configuratorName = action.payload
        },
        setLang: (state, action: PayloadAction<string | null>) => {
            state.lang = action.payload
        },
        setConfigurationId: (state, action: PayloadAction<string>) => {
            state.configurationId = action.payload
        },
        setSelectedTabId: (state, action: PayloadAction<string>) => {
            state.selectedTabId = action.payload
        },
        updateCuiHash: (state) => {
            state.cuiHash = Date.now().toString()
        }
    }
})

export const selectSelectedTab =
    (rootContainer: ContainerTO) => (state: RootState) => {
        const selectedTabId = state.configurator.selectedTabId
        return (
            rootContainer.children.find(
                (child) => child.id === selectedTabId
            ) || null
        )
    }

export const selectElbridgeId = (state: RootState) =>
    state.configurator.elbridgeId

export const selectCuiHash = (state: RootState) => state.configurator.cuiHash

export const selectConfigurationId = (state: RootState) =>
    state.configurator.configurationId

export const selectConfiguratorId = (state: RootState) =>
    state.configurator.configuratorId

export const selectConfiguratorName = (state: RootState) =>
    state.configurator.configuratorName

export const {
    setElbridgeId,
    setHouseId,
    setConfiguratorId,
    setConfiguratorName,
    setLang,
    setConfigurationId,
    setSelectedTabId,
    updateCuiHash
} = configurator.actions
export default configurator.reducer
